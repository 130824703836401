import { Employee, LinkType, Shortlink } from "../types/types";

const mapStringToLink = (linkString: string): LinkType => {
    switch (linkString) {
            case 'contact':
                return LinkType.contact;
            case 'email':
                return LinkType.email;
            case 'linkedin':
                return LinkType.linkedin;
            default:
                return LinkType.contact;
        }
}

const mapDataToLink = (linkObject:any) => linkObject && {
        type: mapStringToLink(linkObject.type),
        url: linkObject.url
    }

export const mapDataToEmployee = (id:string, obj: any): Employee => {
    if (obj) {
        return obj && {
            id: id,
            name: obj.name,
            position: obj.position,
            image: obj.image,
            links: obj.links.map((l: any) => mapDataToLink(l)),
        }
    } else {
        throw new Error(`impossible to map data to Employee (${obj})`);
    }
}

export const mapDataToShortlink = (obj: any): Shortlink => {
    if (obj) {
        return obj && {
            url: obj.url,
        }
    } else {
        throw new Error(`impossible to map data to Shortlink (${obj})`);
    }
}