import { getEmployeDocument, getShortlinkDocument } from "../database";
import { mapDataToEmployee, mapDataToShortlink } from "../mapping/EmployeesMapper";

export const getEmployee = async (term: string) => {
    const {id, data} = await getEmployeDocument('/employees', term);
    if (data == null) {
        throw new Error('box not found');
    }
    return mapDataToEmployee(id, data);
}

export const getShortlink = async (term: string) => {
    const data = await getShortlinkDocument('/shortlinks', term);
    if (data == null) {
        throw new Error('box not found');
    }
    return mapDataToShortlink(data);
}