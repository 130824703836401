import React from 'react'
import Style from "styled-components";
import { ThemeContext } from "styled-components";
import { ColorMode, ColorStyle, FontStyle, FontType } from '../../utils/types/types'
import { Trans } from "react-i18next";

interface LabelProps {
    text: string;
    fontStyle: FontStyle;
    type: FontType;
    color: ColorStyle;
    mode: ColorMode;
    align: string;
    margin?: string;
    children?: any
}

const Label = ({ text, fontStyle, type, color, mode, align, margin, children }: LabelProps) => {
    const theme = React.useContext(ThemeContext);
    switch (fontStyle) {
        case FontStyle.header:
            return <Title margin={margin} align={align} color={theme.color.text[mode][color]} fontStyle={theme.fontStyle[fontStyle]} type={theme.fontType[type]}>{<Trans i18nKey={text}>{children}</Trans>}</Title>;
        case FontStyle.button:
            return <Body margin={margin} align={align} color={theme.color.text[mode][color]} fontStyle={theme.fontStyle[fontStyle]} type={theme.fontType[type]}>{<Trans i18nKey={text}>{children}</Trans>}</Body>;
        case FontStyle.body:
            return <Body margin={margin} align={align} color={theme.color.text[mode][color]} fontStyle={theme.fontStyle[fontStyle]} type={theme.fontType[type]}>{<Trans i18nKey={text}>{children}</Trans>}</Body>;
        case FontStyle.info:
            return <Body margin={margin} align={align} color={theme.color.text[mode][color]} fontStyle={theme.fontStyle[fontStyle]} type={theme.fontType[type]}>{<Trans i18nKey={text}>{children}</Trans>}</Body>;
    }
}

interface ContentProps {
    align: string;
    color: string;
    fontStyle: string;
    type: string;
    margin?: string;
}

const Title = Style.h1<ContentProps>`
    ${props => props.margin ? `margin: ${props.margin};` : null}
    ${props => props.type};
    ${props => props.fontStyle};
    text-align: ${props => props.align};
    color: ${props => props.color};
    position: relative;
    align-self: stretch;
    flex-grow: 0;
    flex: none;
`;

const Body = Style.p<ContentProps>`
    ${props => props.margin ? `margin: ${props.margin};` : null}
    ${props => props.type};
    ${props => props.fontStyle};
    text-align: ${props => props.align};
    color: ${props => props.color};
    position: relative;
    flex-grow: 0;
    flex: none;
    height: min-content;
`;

export default Label;
