import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// wording sheet key = 1W8wL4ujBx2CFGcAaw2h6sFpG8gikxMabDD6Eat17vMQ
// wording link = https://docs.google.com/spreadsheets/d/1W8wL4ujBx2CFGcAaw2h6sFpG8gikxMabDD6Eat17vMQ/edit?usp=sharing
i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    resources: {
        fr: {
            translations: {
                "loading.description": "Chargement en cours...",
                "profile.add_contact.button": "Ajouter la fiche contact",
                "profile.mail.button": "Envoyer un email",
                "profile.linkedin.button": "Ajouter à mon réseau",
                "profile.search.textfield.button": "Rechercher",
                "profile.not_found": "Nous n'avons rien trouvé pour <1>{{term}}</1>, vérifiez l'orthographe.",
            }
        }, en: {
            translations: {
                "loading.description": "Loading your content...",
                "profile.add_contact.button": "Add the contact card",
                "profile.mail.button": "Send an email",
                "profile.linkedin.button": "Connect on Linkedin",
                "profile.search.textfield.button": "Search",
                "profile.not_found": "We didn't find anything for <1>{{term}}</1>, check the spelling.",
            }
        }
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
