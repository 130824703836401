import 'firebase/firestore' // <- needed if using firestore
import firebase from 'firebase/app'

export const getEmployeDocument = async (collection: string, term: string) => {
    const docs = (await firebase.firestore().collection(collection).where('terms', 'array-contains', term).get()).docs;
    const doc = docs[0]
    const data = doc.data()
    // console.log(`document fetched`, data);
    return {
        id:doc.id, 
        data:data
    };
}

export const getShortlinkDocument = async (collection: string, term: string) => {
    const docRef = firebase.firestore().collection(collection).doc(term)
    const doc = await docRef.get();
    const data = doc.data()
    // Log the hit in the document if it exists
    if (data) {
        if (data.hits && data.lastHit) {
            // Not the first time
            await docRef.update({
                hits: firebase.firestore.FieldValue.increment(1),
                lastHit: firebase.firestore.Timestamp.now()
            });
        } else {
            // It is the first time
            await docRef.set({
                hits: 1,
                lastHit: firebase.firestore.Timestamp.now()
            }, { merge: true });
        }
    }
    // console.log(`document fetched`, data);
    return data
}